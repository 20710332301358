html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  -webkit-text-size-adjust: none;
}
mark {
  background-color: transparent;
  color: inherit;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="text"],
input[type="email"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  line-height: 1;
  min-height: var(--viewport-height);
  min-width: 320px;
  overflow-x: hidden;
  word-wrap: break-word;
  background-color: #000000;
}
body:before {
  background-attachment: scroll;
  content: "";
  display: block;
  height: var(--background-height);
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: scale(1);
  width: 100vw;
  z-index: 0;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20preserveAspectRatio%3D%22none%22%3E%20%3Cstyle%20type%3D%22text%2Fcss%22%3E%20path%20%7B%20fill%3A%20none%3B%20stroke%3A%20%230F0F0F%3B%20stroke-width%3A%201.01px%3B%20stroke-linecap%3A%20round%3B%20stroke-linejoin%3A%20miter%3B%20%7D%20%3C%2Fstyle%3E%20%3Cpath%20vector-effect%3D%22non-scaling-stroke%22%20d%3D%22M256%2C128L0%2C384l-192-192%20M256%2C256%20M256%2C384%20M256%2C128v128%20M256%2C128l256%2C256l192-192%20M256%2C256%20M256%2C384%20M256%2C128%20M256%2C384%20M256%2C256%20M-64%2C704%20M0%2C384%20M511.7%2C512L256%2C256L0.3%2C512%20M512%2C384%20M0.3%2C512%20M-192%2C320L0.3%2C512%20M704%2C320L511.7%2C512%20M-192%2C448%20L0%2C640l256-256l256%2C256l192-192%20M-192-192L0%2C0l256-256%20M256-128%20M256%2C0%20M256-128V0%20M256-256L512%2C0l192-192%20M256-128%20M256%2C0%20M256-256%20M256%2C0%20M256-128%20M0.3%2C128%20M-192-64L0.3%2C128%20M704-64L511.7%2C128%20M-192%2C64L0%2C256L256%2C0l256%2C256L704%2C64%20M512%2C256v128%20M0%2C256v128%20M0-192%20V0l0.3%2C128L256-128l255.7%2C256L512%2C0v-192%20M256%2C640V512V384%22%20%2F%3E%3C%2Fsvg%3E");
  background-size: 615px;
  background-position: center;
  background-repeat: repeat;
}
body:after {
  background-color: #171a1c;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: scale(1);
  transition: opacity 1s ease-in-out 0s, visibility 1s 0s;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}
body.is-loading:after {
  opacity: 1;
  visibility: visible;
}
:root {
  --background-height: 100vh;
  --site-language-alignment: left;
  --site-language-direction: ltr;
  --site-language-flex-alignment: flex-start;
  --site-language-indent-left: 1;
  --site-language-indent-right: 0;
  --viewport-height: 100vh;
}
html {
  font-size: 18pt;
}
u {
  text-decoration: underline;
}
strong {
  color: inherit;
  font-weight: bolder;
}
em {
  font-style: italic;
}
code {
  background-color: rgba(144, 144, 144, 0.25);
  border-radius: 0.25em;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 0.9em;
  font-weight: normal;
  letter-spacing: 0;
  margin: 0 0.25em;
  padding: 0.25em 0.5em;
  text-indent: 0;
}
mark {
  background-color: rgba(144, 144, 144, 0.25);
}
spoiler-text {
  -webkit-text-stroke: 0;
  background-color: rgba(32, 32, 32, 0.75);
  text-shadow: none;
  text-stroke: 0;
  color: transparent;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
}
spoiler-text.active {
  color: #ffffff;
  cursor: text;
}
s {
  text-decoration: line-through;
}
sub {
  font-size: smaller;
  vertical-align: sub;
}
sup {
  font-size: smaller;
  vertical-align: super;
}
a {
  color: inherit;
  text-decoration: underline;
  transition: color 0.25s ease;
}
a[onclick]:not([href]) {
  cursor: pointer;
}
deferred-script {
  display: none;
}
#wrapper {
  -webkit-overflow-scrolling: touch;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: var(--viewport-height);
  overflow: hidden;
  position: relative;
  z-index: 2;
}
#main {
  --alignment: center;
  --flex-alignment: center;
  --indent-left: 1;
  --indent-right: 1;
  --border-radius-tl: 0;
  --border-radius-tr: 0;
  --border-radius-br: 0;
  --border-radius-bl: 0;
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  max-width: 100%;
  position: relative;
  text-align: var(--alignment);
  z-index: 1;
  transition: opacity 0.5s ease-in-out 0s;
}
#main > .inner {
  --padding-horizontal: 3rem;
  --padding-vertical: 6rem;
  --spacing: 2rem;
  --width: 65rem;
  border-radius: var(--border-radius-tl) var(--border-radius-tr)
    var(--border-radius-br) var(--border-radius-bl);
  max-width: 100%;
  position: relative;
  width: var(--width);
  z-index: 1;
  padding: var(--padding-vertical) var(--padding-horizontal);
}
#main > .inner > * {
  margin-top: var(--spacing);
  margin-bottom: var(--spacing);
}
#main > .inner > :first-child {
  margin-top: 0 !important;
}
#main > .inner > :last-child {
  margin-bottom: 0 !important;
}
#main > .inner > .full {
  margin-left: calc(var(--padding-horizontal) * -1);
  max-width: calc(100% + calc(var(--padding-horizontal) * 2) + 0.4725px);
  width: calc(100% + calc(var(--padding-horizontal) * 2) + 0.4725px);
}
#main > .inner > .full:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  margin-top: calc(var(--padding-vertical) * -1) !important;
}
#main > .inner > .full:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  margin-bottom: calc(var(--padding-vertical) * -1) !important;
}
#main > .inner > .full.screen {
  border-radius: 0 !important;
  max-width: 100vw;
  position: relative;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  right: auto;
}
body.is-loading #main {
  opacity: 0;
}
body #wrapper:after {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: 4.125rem;
  left: calc(50% - 2.0625rem);
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: calc(50% - 2.0625rem);
  transition: opacity 1s ease, visibility 1s;
  visibility: hidden;
  width: 4.125rem;
  z-index: 100000;
}
body.is-loading {
  pointer-events: none;
}
body.is-loading.with-loader #wrapper:after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iOTZweCIgaGVpZ2h0PSI5NnB4IiB2aWV3Qm94PSIwIDAgOTYgOTYiIHpvb21BbmRQYW49ImRpc2FibGUiPjxzdHlsZT5jaXJjbGUgeyBmaWxsOiB0cmFuc3BhcmVudDsgc3Ryb2tlOiAjMDBGRkMyOyBzdHJva2Utd2lkdGg6IDJweDsgfTwvc3R5bGU+PGRlZnM+PGNsaXBQYXRoIGlkPSJjb3JuZXIiPjxwb2x5Z29uIHBvaW50cz0iMCwwIDQ4LDAgNDgsNDggOTYsNDggOTYsOTYgMCw5NiIgLz48L2NsaXBQYXRoPjwvZGVmcz48ZyBjbGlwLXBhdGg9InVybCgjY29ybmVyKSI+PGNpcmNsZSBjeD0iNDgiIGN5PSI0OCIgcj0iMzIiIHZlY3Rvci1lZmZlY3Q9Im5vbi1zY2FsaW5nLXN0cm9rZSIgLz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIGF0dHJpYnV0ZVR5cGU9IlhNTCIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDQ4IDQ4IiB0bz0iMzYwIDQ4IDQ4IiBkdXI9IjFzIiByZXBlYXREdXI9ImluZGVmaW5pdGUiIC8+PC9nPjwvc3ZnPg==");
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}
body.is-playing.with-loader #wrapper:after {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 0.25s ease, transform 0.75s ease, visibility 0.25s;
  visibility: hidden;
}
body.is-instant #main,
body.is-instant #main > .inner > *,
body.is-instant #main > .inner > section > * {
  transition: none !important;
}
body.is-instant:after {
  display: none !important;
  transition: none !important;
}
.buttons {
  cursor: default;
  display: flex;
  justify-content: var(--flex-alignment);
  letter-spacing: 0;
  padding: 0;
}
.buttons li {
  max-width: 100%;
  text-align: var(--alignment);
}
.buttons li a {
  align-items: center;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
}
.buttons.style2 {
  gap: 0.75rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.buttons.style2 li a {
  display: inline-block;
  width: auto;
  height: 1.625rem;
  line-height: calc(1.625rem - 4px);
  padding: 0 0.8125rem;
  vertical-align: middle;
  font-family: "Inter", sans-serif;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 0.5rem;
  direction: var(--site-language-direction);
  overflow: hidden;
  text-overflow: ellipsis;
  transition: transform 0.25s ease, color 0.25s ease,
    background-color 0.25s ease, border-color 0.25s ease;
}
.buttons.style2 .button {
  color: #ffd600;
  border: solid 2px #ffd600;
}
.buttons.style2 li a:hover {
  transform: scale(1.0425);
}
.buttons.style3 {
  gap: 0.75rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.buttons.style3 li a {
  display: inline-flex;
  width: 15.625rem;
  height: 2.625rem;
  line-height: 2.625rem;
  padding: 0 0.5rem;
  vertical-align: middle;
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-weight: 600;
  border-radius: 0.625rem;
  flex-direction: row-reverse;
  transition: transform 0.25s ease, color 0.25s ease,
    background-color 0.25s ease, border-color 0.25s ease;
}
.buttons.style3 li a svg {
  display: block;
  fill: #ff5454;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  min-width: 16px;
  width: 1em;
  margin-left: 0.75rem;
  margin-right: calc(-0.125em + 0rem);
  transition: fill 0.25s ease;
}
.buttons.style3 li a .label {
  direction: var(--site-language-direction);
  overflow: hidden;
}
.buttons.style3 .button {
  color: #e2e2b6;
  border: solid 2px #e2e2b6;
}
.buttons.style3 .button svg {
  fill: #04a6e1;
}
.buttons.style3 li a:hover {
  transform: scale(1.0425);
}
.buttons.style4 {
  gap: 0.75rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.buttons.style4:not(:first-child) {
  margin-top: 1.875rem !important;
}
.buttons.style4:not(:last-child) {
  margin-bottom: 1.875rem !important;
}
.buttons.style4 li a {
  display: inline-flex;
  width: auto;
  height: 3.5rem;
  line-height: 3.5rem;
  padding: 0 1.75rem;
  vertical-align: middle;
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-weight: 600;
  border-radius: 0.5rem;
  flex-direction: row-reverse;
  transition: transform 0.25s ease, color 0.25s ease,
    background-color 0.25s ease, border-color 0.25s ease;
}
.buttons.style4 li a svg {
  display: block;
  fill: #ffffff;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  min-width: 16px;
  width: 1em;
  margin-left: 0.75rem;
  margin-right: calc(-0.125em + 0rem);
  transition: fill 0.25s ease;
}
.buttons.style4 li a .label {
  direction: var(--site-language-direction);
  overflow: hidden;
}
.buttons.style4 .button {
  color: #ffffff;
  border: solid 2px #ffd600;
}
.buttons.style4 .button svg {
  fill: #ff0000;
}
.buttons.style4 .button:hover {
  border-color: #ff0000 !important;
  color: #ff0000 !important;
}
.buttons.style4 .button:hover svg {
  fill: #ff0000 !important;
}
.buttons.style4 li a:hover {
  transform: scale(1.0425);
}
.buttons.style6 {
  gap: 0.75rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.buttons.style6:not(:first-child) {
  margin-top: 1.875rem !important;
}
.buttons.style6:not(:last-child) {
  margin-bottom: 1.875rem !important;
}
.buttons.style6 li a {
  display: inline-flex;
  width: 14.75rem;
  height: 2.75rem;
  line-height: 2.75rem;
  padding: 0 0.5rem;
  vertical-align: middle;
  font-family: "Inter", sans-serif;
  font-size: 0.875em;
  font-weight: 600;
  border-radius: 0.5rem;
  flex-direction: row-reverse;
  transition: transform 0.25s ease, color 0.25s ease,
    background-color 0.25s ease, border-color 0.25s ease;
}
.buttons.style6 li a svg {
  display: block;
  fill: #ffffff;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  min-width: 16px;
  width: 1em;
  margin-left: 0.75rem;
  margin-right: calc(-0.125em + 0rem);
  transition: fill 0.25s ease;
}
.buttons.style6 li a .label {
  direction: var(--site-language-direction);
  overflow: hidden;
}
.buttons.style6 .button {
  color: #ffffff;
  border: solid 2px #04a6e1;
}
.buttons.style6 .button svg {
  fill: #04a6e1;
}
.buttons.style6 .button:hover {
  border-color: #04a6e1 !important;
  color: #04a6e1 !important;
}
.buttons.style6 .button:hover svg {
  fill: #04a6e1 !important;
}
.buttons.style6 li a:hover {
  transform: scale(1.0425);
}
h1,
h2,
h3,
p {
  direction: var(--site-language-direction);
  position: relative;
}
h1 span.p,
h2 span.p,
h3 span.p,
p span.p {
  display: block;
  position: relative;
}
h1 span[style],
h2 span[style],
h3 span[style],
p span[style],
h1 strong,
h2 strong,
h3 strong,
p strong,
h1 a,
h2 a,
h3 a,
p a,
h1 code,
h2 code,
h3 code,
p code,
h1 mark,
h2 mark,
h3 mark,
p mark,
h1 spoiler-text,
h2 spoiler-text,
h3 spoiler-text,
p spoiler-text {
  -webkit-text-fill-color: currentcolor;
}
h1.style9,
h2.style9,
h3.style9,
p.style9 {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 2.625em;
  line-height: 1.125;
  font-weight: 600;
}
h1.style9 u,
h2.style9 u,
h3.style9 u,
p.style9 u {
  text-decoration-color: #04a6e1;
}
h1.style9 mark,
h2.style9 mark,
h3.style9 mark,
h1.style9 mark,
h2.style9 mark,
h3.style9 mark,
p.style9 mark {
  color: #04a6e1;
  background-color: transparent;
}
h1.style9 a,
h2.style9 a,
h3.style9 a,
p.style9 a {
  text-decoration: underline;
}
h1.style9 a:hover,
h2.style9 a:hover,
h3.style9 a:hover,
p.style9 a:hover {
  text-decoration: none;
}
h1.style9 span.p:nth-child(n + 2),
h2.style9 span.p:nth-child(n + 2),
h3.style9 span.p:nth-child(n + 2),
p.style9 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
h1.style2,
h2.style2,
h3.style2,
p.style2 {
  color: #949494;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.05rem;
  width: 100%;
  font-size: 0.875em;
  line-height: 0.75;
  font-weight: 300;
}
h1.style2 a,
h2.style2 a,
h3.style2 a,
p.style2 a {
  text-decoration: underline;
}
h1.style2 a:hover,
h2.style2 a:hover,
h3.style2 a:hover,
p.style2 a:hover {
  text-decoration: none;
}
h1.style2 span.p:nth-child(n + 2),
h2.style2 span.p:nth-child(n + 2),
h3.style2 span.p:nth-child(n + 2),
p.style2 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
h1.style4,
h2.style4,
h3.style4,
p.style4 {
  text-transform: uppercase;
  color: #ffd600;;
  font-family: "Inter", sans-serif;
  font-size: 0.75em;
  line-height: 1.5;
  font-weight: 600;
}
h1.style4 a,
h2.style4 a,
h3.style4 a,
p.style4 a {
  text-decoration: underline;
}
h1.style4 a:hover,
h2.style4 a:hover,
h3.style4 a:hover,
p.style4 a:hover {
  text-decoration: none;
}
h1.style4 span.p:nth-child(n + 2),
h2.style4 span.p:nth-child(n + 2),
h3.style4 span.p:nth-child(n + 2),
p.style4 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
h1.style11,
h2.style11,
h3.style11,
p.style11 {
  text-align: center;
  color: #bdbdbd;
  font-family: "Inter", sans-serif;
  font-size: 1.5em;
  line-height: 1.125;
  font-weight: 700;
}
h1.style11 u,
h2.style11 u,
h3.style11 u,
p.style11 u {
  text-decoration-color: #ff0000;
}
h1.style11 mark,
h2.style11 mark,
h3.style11 mark,
p.style11 mark {
  color: #ff0000;
  background-color: transparent;
}
h1.style11 a,
h2.style11 a,
h3.style11 a,
p.style11 a {
  text-decoration: underline;
}
h1.style11 a:hover,
h2.style11 a:hover,
h3.style11 a:hover,
p.style11 a:hover {
  text-decoration: none;
}
h1.style11 span.p:nth-child(n + 2),
h2.style11 span.p:nth-child(n + 2),
h3.style11 span.p:nth-child(n + 2),
p.style11 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
h1.style12,
h2.style12,
h3.style12,
p.style12 {
  text-align: center;
  color: #bdbdbd;
  font-family: "Inter", sans-serif;
  font-size: 1.5em;
  line-height: 1.125;
  font-weight: 600;
}
h1.style12 u,
h2.style12 u,
h3.style12 u,
p.style12 u {
  text-decoration-color: #ffa800;
}
h1.style12 mark,
h2.style12 mark,
h3.style12 mark,
p.style12 mark {
  color: #ff0000;
  background-color: transparent;
}
h1.style12 a,
h2.style12 a,
h3.style12 a,
p.style12 a {
  text-decoration: underline;
}
h1.style12 a:hover,
h2.style12 a:hover,
h3.style12 a:hover,
p.style12 a:hover {
  text-decoration: none;
}
h1.style12 span.p:nth-child(n + 2),
h2.style12 span.p:nth-child(n + 2),
h3.style12 span.p:nth-child(n + 2),
p.style12 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
h1.style10,
h2.style10,
h3.style10,
p.style10 {
  text-align: center;
  color: #bdbdbd;
  font-family: "Inter", sans-serif;
  font-size: 1.5em;
  line-height: 1.125;
  font-weight: 600;
}
h1.style10 u,
h2.style10 u,
h3.style10 u,
p.style10 u {
  text-decoration-color: #0075ff;
}
h1.style10 mark,
h2.style10 mark,
h3.style10 mark,
p.style10 mark {
  color: #ff0000;
  background-color: transparent;
}
h1.style10 a,
h2.style10 a,
h3.style10 a,
p.style10 a {
  text-decoration: underline;
}
h1.style10 a:hover,
h2.style10 a:hover,
h3.style10 a:hover,
p.style10 a:hover {
  text-decoration: none;
}
h1.style10 span.p:nth-child(n + 2),
h2.style10 span.p:nth-child(n + 2),
h3.style10 span.p:nth-child(n + 2),
p.style10 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
h1.style3,
h2.style3,
h3.style3,
p.style3 {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 2.625em;
  line-height: 1.125;
  font-weight: 600;
}
h1.style3 u,
h2.style3 u,
h3.style3 u,
p.style3 u {
  text-decoration-color: #ff0000;
}
h1.style3 mark,
h2.style3 mark,
h3.style3 mark,
p.style3 mark {
  color: #ffd600;
  background-color: transparent;
}
h1.style3 a,
h2.style3 a,
h3.style3 a,
p.style3 a {
  text-decoration: underline;
}
h1.style3 a:hover,
h2.style3 a:hover,
h3.style3 a:hover,
p.style3 a:hover {
  text-decoration: none;
}
h1.style3 span.p:nth-child(n + 2),
h2.style3 span.p:nth-child(n + 2),
h3.style3 span.p:nth-child(n + 2),
p.style3 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
h1.style13,
h2.style13,
h3.style13,
p.style13 {
  color: #6e6e6e;
  font-family: "Inter", sans-serif;
  font-size: 1.25em;
  line-height: 1.125;
  font-weight: 600;
}
h1.style13 u,
h2.style13 u,
h3.style13 u,
p.style13 u {
  text-decoration-color: #ff0000;
}
h1.style13 mark,
h2.style13 mark,
h3.style13 mark,
p.style13 mark {
  color: #ff0000;
  background-color: transparent;
}
h1.style13 a,
h2.style13 a,
h3.style13 a,
p.style13 a {
  text-decoration: underline;
}
h1.style13 a:hover,
h2.style13 a:hover,
h3.style13 a:hover,
p.style13 a:hover {
  text-decoration: none;
}
h1.style13 span.p:nth-child(n + 2),
h2.style13 span.p:nth-child(n + 2),
h3.style13 span.p:nth-child(n + 2),
p.style13 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
h1.style8,
h2.style8,
h3.style8,
p.style8 {
  color: #8f8f8f;
  font-family: "Inter", sans-serif;
  font-size: 1.5em;
  line-height: 1.125;
  font-weight: 600;
}
h1.style8 u,
h2.style8 u,
h3.style8 u,
p.style8 u {
  text-decoration-color: transparent;
}
h1.style8 mark,
h2.style8 mark,
h3.style8 mark,
p.style8 mark {
  color: #ff0000;
  background-color: transparent;
}
h1.style8 a,
h2.style8 a,
h3.style8 a,
p.style8 a {
  text-decoration: underline;
}
h1.style8 a:hover,
h2.style8 a:hover,
h3.style8 a:hover,
p.style8 a:hover {
  text-decoration: none;
}
h1.style8 span.p:nth-child(n + 2),
h2.style8 span.p:nth-child(n + 2),
h3.style8 span.p:nth-child(n + 2),
p.style8 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
h1.style7,
h2.style7,
h3.style7,
p.style7 {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 2.625em;
  line-height: 1.125;
  font-weight: 600;
}
h1.style7 u,
h2.style7 u,
h3.style7 u,
p.style7 u {
  text-decoration-color: #ff0000;
}
h1.style7 mark,
h2.style7 mark,
h3.style7 mark,
p.style7 mark {
  color: #ff0000;
  background-color: transparent;
}
h1.style7 a,
h2.style7 a,
h3.style7 a,
p.style7 a {
  text-decoration: underline;
}
h1.style7 a:hover,
h2.style7 a:hover,
h3.style7 a:hover,
p.style7 a:hover {
  text-decoration: none;
}
h1.style7 span.p:nth-child(n + 2),
h2.style7 span.p:nth-child(n + 2),
h3.style7 span.p:nth-child(n + 2),
p.style7 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
h1.style6,
h2.style6,
h3.style6,
p.style6 {
  color: rgba(94, 94, 94, 0.749);
  font-family: "Inter", sans-serif;
  font-size: 1.75em;
  line-height: 1.125;
  font-weight: 600;
}
h1.style6 u,
h2.style6 u,
h3.style6 u,
p.style6 u {
  text-decoration-color: #ff0000;
}
h1.style6 a,
h2.style6 a,
h3.style6 a,
p.style6 a {
  text-decoration: underline;
}
h1.style6 a:hover,
h2.style6 a:hover,
h3.style6 a:hover,
p.style6 a:hover {
  text-decoration: none;
}
h1.style6 span.p:nth-child(n + 2),
h2.style6 span.p:nth-child(n + 2),
h3.style6 span.p:nth-child(n + 2),
p.style6 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}
.video {
  position: relative;
}
.video video {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.video .frame {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}
.video .frame:before {
  content: "";
  display: block;
  width: 100%;
}
.video .frame iframe {
  bottom: 0px;
  height: 100%;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  background-color: #000000;
}
.video .frame .first-information-video-first-video {
  bottom: 0px;
  height: 100%;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  background-color: #000000;
}
.video.full video {
  display: block;
}
.video.full:first-child video {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.video.full:last-child video {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.video.full .frame {
  display: block;
}
.video.full:first-child .frame {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.video.full:last-child .frame {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
#video01 video {
  width: 20.5rem;
  margin: auto;
}
#video01 .frame {
  /* width: 59.5rem; */
  display: flex;
  justify-content: center;
  margin: auto;
}
#video01 .frame:before {
  padding-top: 56.25%;
}
.video.style1 video {
  width: 15.125rem;
}
.video.style1 .frame {
  width: 25.125rem;
}
.video.style1 .frame:before {
  padding-top: 56.25%;
}
.video.style2 {
  text-align: center;
}
.video.style2 video {
  width: 14.375rem;
}
.video.style2 .frame {
  width: 14.375rem;
}
.video.style2 .frame:before {
  padding-top: 177.77777777778%;
}
.container {
  position: relative;
}
.container > .wrapper {
  vertical-align: top;
  position: relative;
  max-width: 100%;
  border-radius: inherit;
}
.container > .wrapper > .inner {
  vertical-align: top;
  position: relative;
  max-width: 100%;
  border-radius: inherit;
  text-align: var(--alignment);
}
#main .container.full:first-child > .wrapper {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
#main .container.full:last-child > .wrapper {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
#main .container.full:first-child > .wrapper > .inner {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
#main .container.full:last-child > .wrapper > .inner {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.container.style1 {
  display: flex;
  width: 100%;
  min-height: 40rem;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.container.style1:not(:first-child) {
  margin-top: 6rem !important;
}
.container.style1:not(:last-child) {
  margin-bottom: 6rem !important;
}
.container.style1 > .wrapper > .inner {
  --gutters: 4rem;
  --padding-horizontal: 0rem;
  --padding-vertical: 2.25rem;
  padding: var(--padding-vertical) var(--padding-horizontal);
}
.container.style1 > .wrapper {
  max-width: var(--width);
  width: 100%;
}
.container.style1 video {
  border-radius: inherit;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}
.container.style1:before {
  border-radius: inherit;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.container.style1.default > .wrapper > .inner > * {
  margin-bottom: var(--spacing);
  margin-top: var(--spacing);
}
.container.style1.default > .wrapper > .inner > *:first-child {
  margin-top: 0 !important;
}
.container.style1.default > .wrapper > .inner > *:last-child {
  margin-bottom: 0 !important;
}
.container.style1.columns > .wrapper > .inner {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
.container.style1.columns > .wrapper > .inner > * {
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  text-align: var(--alignment);
  padding: 0 0 0 var(--gutters);
}
.container.style1.columns > .wrapper > .inner > * > * {
  margin-bottom: var(--spacing);
  margin-top: var(--spacing);
}
.container.style1.columns > .wrapper > .inner > * > *:first-child {
  margin-top: 0 !important;
}
.container.style1.columns > .wrapper > .inner > * > *:last-child {
  margin-bottom: 0 !important;
}
.container.style1.columns > .wrapper > .inner > *:first-child {
  margin-left: calc(var(--gutters) * -1);
}
.container.style1.default > .wrapper > .inner > .full {
  margin-left: calc(var(--padding-horizontal) * -1);
  max-width: none !important;
  width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
}
.container.style1.default > .wrapper > .inner > .full:first-child {
  margin-top: calc(var(--padding-vertical) * -1) !important;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.container.style1.default > .wrapper > .inner > .full:last-child {
  margin-bottom: calc(var(--padding-vertical) * -1) !important;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.container.style1.columns > .wrapper > .inner > div > .full {
  margin-left: calc(var(--gutters) * -0.5);
  max-width: none !important;
  width: calc(100% + var(--gutters) + 0.4725px);
}
.container.style1.columns > .wrapper > .inner > div:first-child > .full {
  margin-left: calc(var(--padding-horizontal) * -1);
  width: calc(
    100% + var(--padding-horizontal) + calc(var(--gutters) * 0.5) + 0.4725px
  );
}
.container.style1.columns > .wrapper > .inner > div:last-child > .full {
  width: calc(
    100% + var(--padding-horizontal) + calc(var(--gutters) * 0.5) + 0.4725px
  );
}
.container.style1.columns > .wrapper > .inner > div > .full:first-child {
  margin-top: calc(var(--padding-vertical) * -1) !important;
}
.container.style1.columns > .wrapper > .inner > div > .full:last-child {
  margin-bottom: calc(var(--padding-vertical) * -1) !important;
}
.container.style1.columns > .wrapper > .inner > .full {
  align-self: stretch;
  min-height: 40rem;
}
.container.style1.columns > .wrapper > .inner > .full:first-child {
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}
.container.style1.columns > .wrapper > .inner > .full:last-child {
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
}
.container.style1.columns
  > .wrapper
  > .inner
  > .full
  > .full:first-child:last-child {
  border-radius: inherit;
  height: calc(100% + (var(--padding-vertical) * 2));
}
.container.style1.columns
  > .wrapper
  > .inner
  > .full
  > .full:first-child:last-child
  > * {
  border-radius: inherit;
  height: 100%;
  position: absolute;
  width: 100%;
}
.container.style2:not(:first-child) {
  margin-top: 6rem !important;
}
.container.style2:not(:last-child) {
  margin-bottom: 6rem !important;
}
.container.style2 > .wrapper {
  display: inline-block;
  width: 61rem;
  background-color: transparent;
}
.container.style2 > .wrapper > .inner {
  --gutters: 4rem;
  --padding-horizontal: 6rem;
  --padding-vertical: 0rem;
  padding: var(--padding-vertical) var(--padding-horizontal);
}
.container.style2.default > .wrapper > .inner > * {
  margin-bottom: var(--spacing);
  margin-top: var(--spacing);
}
.container.style2.default > .wrapper > .inner > *:first-child {
  margin-top: 0 !important;
}
.container.style2.default > .wrapper > .inner > *:last-child {
  margin-bottom: 0 !important;
}
.container.style2.columns > .wrapper > .inner {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
.container.style2.columns > .wrapper > .inner > * {
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  text-align: var(--alignment);
  padding: 0 0 0 var(--gutters);
}
.container.style2.columns > .wrapper > .inner > * > * {
  margin-bottom: var(--spacing);
  margin-top: var(--spacing);
}
.container.style2.columns > .wrapper > .inner > * > *:first-child {
  margin-top: 0 !important;
}
.container.style2.columns > .wrapper > .inner > * > *:last-child {
  margin-bottom: 0 !important;
}
.container.style2.columns > .wrapper > .inner > *:first-child {
  margin-left: calc(var(--gutters) * -1);
}
.container.style2.default > .wrapper > .inner > .full {
  margin-left: calc(var(--padding-horizontal) * -1);
  max-width: none !important;
  width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
}
.container.style2.default > .wrapper > .inner > .full:first-child {
  margin-top: calc(var(--padding-vertical) * -1) !important;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.container.style2.default > .wrapper > .inner > .full:last-child {
  margin-bottom: calc(var(--padding-vertical) * -1) !important;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.container.style2.columns > .wrapper > .inner > div > .full {
  margin-left: calc(var(--gutters) * -0.5);
  max-width: none !important;
  width: calc(100% + var(--gutters) + 0.4725px);
}
.container.style2.columns > .wrapper > .inner > div:first-child > .full {
  margin-left: calc(var(--padding-horizontal) * -1);
  width: calc(
    100% + var(--padding-horizontal) + calc(var(--gutters) * 0.5) + 0.4725px
  );
}
.container.style2.columns > .wrapper > .inner > div:last-child > .full {
  width: calc(
    100% + var(--padding-horizontal) + calc(var(--gutters) * 0.5) + 0.4725px
  );
}
.container.style2.columns > .wrapper > .inner > div > .full:first-child {
  margin-top: calc(var(--padding-vertical) * -1) !important;
}
.container.style2.columns > .wrapper > .inner > div > .full:last-child {
  margin-bottom: calc(var(--padding-vertical) * -1) !important;
}
.container.style2.columns > .wrapper > .inner > .full {
  align-self: stretch;
}
.container.style2.columns > .wrapper > .inner > .full:first-child {
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}
.container.style2.columns > .wrapper > .inner > .full:last-child {
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
}
.container.style2.columns
  > .wrapper
  > .inner
  > .full
  > .full:first-child:last-child {
  border-radius: inherit;
  height: calc(100% + (var(--padding-vertical) * 2));
}
.container.style2.columns
  > .wrapper
  > .inner
  > .full
  > .full:first-child:last-child
  > * {
  border-radius: inherit;
  height: 100%;
  position: absolute;
  width: 100%;
}
#container03 > .wrapper > .inner > :nth-child(1) {
  width: calc(50% + (var(--gutters) / 2));
}
#container03 > .wrapper > .inner > :nth-child(2) {
  width: calc(50% + (var(--gutters) / 2));
}
#container02 > .wrapper > .inner > :nth-child(1) {
  width: calc(50% + (var(--gutters) / 2));
}
#container02 > .wrapper > .inner > :nth-child(2) {
  width: calc(50% + (var(--gutters) / 2));
}
#container05 > .wrapper > .inner > :nth-child(1) {
  width: calc(50% + (var(--gutters) / 2));
}
#container05 > .wrapper > .inner > :nth-child(2) {
  width: calc(50% + (var(--gutters) / 2));
}
.container.style7 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
}
.container.style7:not(:first-child) {
  margin-top: 12rem !important;
}
.container.style7:not(:last-child) {
  margin-bottom: 6rem !important;
}
.container.style7 > .wrapper > .inner {
  --gutters: 4rem;
  --padding-horizontal: 0rem;
  --padding-vertical: 2.25rem;
  padding: var(--padding-vertical) var(--padding-horizontal);
}
.container.style7 > .wrapper {
  max-width: var(--width);
  width: 100%;
}
.container.style7.default > .wrapper > .inner > * {
  margin-bottom: var(--spacing);
  margin-top: var(--spacing);
}
.container.style7.default > .wrapper > .inner > *:first-child {
  margin-top: 0 !important;
}
.container.style7.default > .wrapper > .inner > *:last-child {
  margin-bottom: 0 !important;
}
.container.style7.columns > .wrapper > .inner {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
.container.style7.columns > .wrapper > .inner > * {
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  text-align: var(--alignment);
  padding: 0 0 0 var(--gutters);
}
.container.style7.columns > .wrapper > .inner > * > * {
  margin-bottom: var(--spacing);
  margin-top: var(--spacing);
}
.container.style7.columns > .wrapper > .inner > * > *:first-child {
  margin-top: 0 !important;
}
.container.style7.columns > .wrapper > .inner > * > *:last-child {
  margin-bottom: 0 !important;
}
.container.style7.columns > .wrapper > .inner > *:first-child {
  margin-left: calc(var(--gutters) * -1);
}
.container.style7.default > .wrapper > .inner > .full {
  margin-left: calc(var(--padding-horizontal) * -1);
  max-width: none !important;
  width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
}
.container.style7.default > .wrapper > .inner > .full:first-child {
  margin-top: calc(var(--padding-vertical) * -1) !important;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.container.style7.default > .wrapper > .inner > .full:last-child {
  margin-bottom: calc(var(--padding-vertical) * -1) !important;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.container.style7.columns > .wrapper > .inner > div > .full {
  margin-left: calc(var(--gutters) * -0.5);
  max-width: none !important;
  width: calc(100% + var(--gutters) + 0.4725px);
}
.container.style7.columns > .wrapper > .inner > div:first-child > .full {
  margin-left: calc(var(--padding-horizontal) * -1);
  width: calc(
    100% + var(--padding-horizontal) + calc(var(--gutters) * 0.5) + 0.4725px
  );
}
.container.style7.columns > .wrapper > .inner > div:last-child > .full {
  width: calc(
    100% + var(--padding-horizontal) + calc(var(--gutters) * 0.5) + 0.4725px
  );
}
.container.style7.columns > .wrapper > .inner > div > .full:first-child {
  margin-top: calc(var(--padding-vertical) * -1) !important;
}
.container.style7.columns > .wrapper > .inner > div > .full:last-child {
  margin-bottom: calc(var(--padding-vertical) * -1) !important;
}
.container.style7.columns > .wrapper > .inner > .full {
  align-self: stretch;
}
.container.style7.columns > .wrapper > .inner > .full:first-child {
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}
.container.style7.columns > .wrapper > .inner > .full:last-child {
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
}
.container.style7.columns
  > .wrapper
  > .inner
  > .full
  > .full:first-child:last-child {
  border-radius: inherit;
  height: calc(100% + (var(--padding-vertical) * 2));
}
.container.style7.columns
  > .wrapper
  > .inner
  > .full
  > .full:first-child:last-child
  > * {
  border-radius: inherit;
  height: 100%;
  position: absolute;
  width: 100%;
}
#container11 > .wrapper > .inner > :nth-child(1) {
  width: calc(33.33333% + (var(--gutters) / 3));
}
#container11 > .wrapper > .inner > :nth-child(2) {
  width: calc(33.33333% + (var(--gutters) / 3));
}
#container11 > .wrapper > .inner > :nth-child(3) {
  width: calc(33.33333% + (var(--gutters) / 3));
}
.container.style6 {
  --alignment: left;
  --flex-alignment: flex-start;
  --indent-left: 1;
  --indent-right: 0;
  display: flex;
  width: 100%;
  min-height: 15rem;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.container.style6:not(:first-child) {
  margin-top: 6rem !important;
}
.container.style6:not(:last-child) {
  margin-bottom: 6rem !important;
}
.container.style6 > .wrapper > .inner {
  --gutters: 4rem;
  --padding-horizontal: 6rem;
  --padding-vertical: 0rem;
  padding: var(--padding-vertical) var(--padding-horizontal);
}
.container.style6 > .wrapper {
  max-width: var(--width);
  width: 100%;
}
.container.style6.default > .wrapper > .inner > * {
  margin-bottom: var(--spacing);
  margin-top: var(--spacing);
}
.container.style6.default > .wrapper > .inner > *:first-child {
  margin-top: 0 !important;
}
.container.style6.default > .wrapper > .inner > *:last-child {
  margin-bottom: 0 !important;
}
.container.style6.columns > .wrapper > .inner {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
.container.style6.columns > .wrapper > .inner > * {
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  text-align: var(--alignment);
  padding: 0 0 0 var(--gutters);
}
.container.style6.columns > .wrapper > .inner > * > * {
  margin-bottom: var(--spacing);
  margin-top: var(--spacing);
}
.container.style6.columns > .wrapper > .inner > * > *:first-child {
  margin-top: 0 !important;
}
.container.style6.columns > .wrapper > .inner > * > *:last-child {
  margin-bottom: 0 !important;
}
.container.style6.columns > .wrapper > .inner > *:first-child {
  margin-left: calc(var(--gutters) * -1);
}
.container.style6.default > .wrapper > .inner > .full {
  margin-left: calc(var(--padding-horizontal) * -1);
  max-width: none !important;
  width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
}
.container.style6.default > .wrapper > .inner > .full:first-child {
  margin-top: calc(var(--padding-vertical) * -1) !important;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.container.style6.default > .wrapper > .inner > .full:last-child {
  margin-bottom: calc(var(--padding-vertical) * -1) !important;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.container.style6.columns > .wrapper > .inner > div > .full {
  margin-left: calc(var(--gutters) * -0.5);
  max-width: none !important;
  width: calc(100% + var(--gutters) + 0.4725px);
}
.container.style6.columns > .wrapper > .inner > div:first-child > .full {
  margin-left: calc(var(--padding-horizontal) * -1);
  width: calc(
    100% + var(--padding-horizontal) + calc(var(--gutters) * 0.5) + 0.4725px
  );
}
.container.style6.columns > .wrapper > .inner > div:last-child > .full {
  width: calc(
    100% + var(--padding-horizontal) + calc(var(--gutters) * 0.5) + 0.4725px
  );
}
.container.style6.columns > .wrapper > .inner > div > .full:first-child {
  margin-top: calc(var(--padding-vertical) * -1) !important;
}
.container.style6.columns > .wrapper > .inner > div > .full:last-child {
  margin-bottom: calc(var(--padding-vertical) * -1) !important;
}
.container.style6.columns > .wrapper > .inner > .full {
  align-self: stretch;
  min-height: 15rem;
}
.container.style6.columns > .wrapper > .inner > .full:first-child {
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}
.container.style6.columns > .wrapper > .inner > .full:last-child {
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
}
.container.style6.columns
  > .wrapper
  > .inner
  > .full
  > .full:first-child:last-child {
  border-radius: inherit;
  height: calc(100% + (var(--padding-vertical) * 2));
}
.container.style6.columns
  > .wrapper
  > .inner
  > .full
  > .full:first-child:last-child
  > * {
  border-radius: inherit;
  height: 100%;
  position: absolute;
  width: 100%;
}
#container09 > .wrapper > .inner > :nth-child(1) {
  width: calc(50% + (var(--gutters) / 2));
}
#container09 > .wrapper > .inner > :nth-child(2) {
  width: calc(50% + (var(--gutters) / 2));
}
#container08 > .wrapper > .inner > :nth-child(1) {
  width: calc(50% + (var(--gutters) / 2));
}
#container08 > .wrapper > .inner > :nth-child(2) {
  width: calc(50% + (var(--gutters) / 2));
}
#container06 > .wrapper > .inner > :nth-child(1) {
  width: calc(50% + (var(--gutters) / 2));
  --alignment: left;
  --flex-alignment: flex-start;
  --indent-left: 1;
  --indent-right: 0;
}
#container06 > .wrapper > .inner > :nth-child(2) {
  width: calc(50% + (var(--gutters) / 2));
  --alignment: right;
  --flex-alignment: flex-end;
  --indent-left: 0;
  --indent-right: 1;
}
.image {
  display: block;
  line-height: 0;
  max-width: 100%;
  position: relative;
}
.image .frame {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  vertical-align: top;
  width: 100%;
}
.image .frame img {
  border-radius: 0 !important;
  max-width: 100%;
  vertical-align: top;
  width: inherit;
}
.image.full .frame {
  display: block;
}
.image.full:first-child .frame {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.image.full:last-child .frame {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.image.style11 {
  text-align: center;
}
.image.style11:not(:first-child) {
  margin-top: 3rem !important;
}
.image.style11:not(:last-child) {
  margin-bottom: 3rem !important;
}
.image.style11 .frame {
  width: 17.25rem;
  height: 17.375rem;
  border-radius: 100%;
  border-color: #ff0000;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0.147rem 0.202rem 4.125rem 0rem #9e5500;
  transition: none;
}
.image.style11 img {
  height: 100% !important;
  object-fit: cover;
  object-position: center;
  width: 100% !important;
  border-radius: inherit !important;
  padding: 0.75rem;
}
.image.style11 .frame img {
  transition: none;
}
.image.style13 {
  text-align: center;
}
.image.style13:not(:first-child) {
  margin-top: 3rem !important;
}
.image.style13:not(:last-child) {
  margin-bottom: 3rem !important;
}
.image.style13 .frame {
  width: 17.25rem;
  height: 17.375rem;
  border-radius: 100%;
  border-color: #6100ff;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0.147rem 0.202rem 0rem 0rem #9e5500;
  transition: none;
}
.image.style13 img {
  height: 100% !important;
  object-fit: cover;
  object-position: center;
  width: 100% !important;
  border-radius: inherit !important;
  padding: 0.75rem;
}
.image.style13 .frame img {
  transition: none;
}
.image.style12 {
  text-align: center;
}
.image.style12 .frame {
  width: 17.25rem;
  height: 17.375rem;
  border-radius: 100%;
  border-color: #0066ff;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0.147rem 0.202rem 0rem 0rem #0094ff;
  transition: none;
}
.image.style12 img {
  height: 100% !important;
  object-fit: cover;
  object-position: center;
  width: 100% !important;
  border-radius: inherit !important;
  padding: 0.75rem;
}
.image.style12 .frame img {
  transition: none;
}
.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--flex-alignment);
  letter-spacing: 0;
  padding: 0;
}
.icons li {
  position: relative;
  z-index: 1;
}
.icons li a {
  align-items: center;
  display: flex;
  justify-content: center;
}
.icons li a svg {
  display: block;
  position: relative;
}
.icons li a + svg {
  display: block;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.icons li a .label {
  display: none;
}
.icons.style1 {
  font-size: 1.5em;
  gap: 1.25rem;
}
.icons.style1 li a {
  border-radius: 100%;
  height: 2em;
  width: 2em;
  transition: transform 0.25s ease, color 0.25s ease,
    background-color 0.25s ease, border-color 0.25s ease;
}
.icons.style1 li a svg {
  height: 60%;
  width: 60%;
  transition: fill 0.25s ease;
}
.icons.style1 a svg {
  fill: #ffffff;
}
.icons.style1 a {
  border: solid 2px #04a6e1;
}
.icons.style1 li a + svg {
  transition: transform 0.25s ease, fill 0.25s ease, stroke 0.25s ease;
}
.icons.style1 li a:hover {
  transform: scale(1.095);
}
.icons.style1 li a:hover + svg {
  transform: scale(1.095);
}
@media (max-width: 1920px) {
}
@media (max-width: 1680px) {
  html {
    font-size: 13pt;
  }
}
@media (max-width: 1280px) {
  html {
    font-size: 13pt;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 980px) {
  html {
    font-size: 11pt;
  }
}
@media (max-width: 736px) {
  html {
    font-size: 11pt;
  }
  #main > .inner {
    --padding-horizontal: 2rem;
    --padding-vertical: 4rem;
    --spacing: 2rem;
  }
  .buttons.style2 {
    gap: 0.75rem;
  }
  .buttons.style2 li a {
    letter-spacing: 0rem;
    font-size: 0.75em;
  }
  .buttons.style3 {
    gap: 0.75rem;
  }
  .buttons.style3 li a {
    letter-spacing: 0rem;
    font-size: 1em;
  }
  .buttons.style3 li a svg {
    width: 1em;
  }
  .buttons.style4 {
    gap: 0.75rem;
  }
  .buttons.style4:not(:first-child) {
    margin-top: 1.40625rem !important;
  }
  .buttons.style4:not(:last-child) {
    margin-bottom: 1.40625rem !important;
  }
  .buttons.style4 li a {
    letter-spacing: 0rem;
    font-size: 1em;
  }
  .buttons.style4 li a svg {
    width: 1em;
  }
  .buttons.style6 {
    gap: 0.75rem;
  }
  .buttons.style6:not(:first-child) {
    margin-top: 1.40625rem !important;
  }
  .buttons.style6:not(:last-child) {
    margin-bottom: 1.40625rem !important;
  }
  .buttons.style6 li a {
    letter-spacing: 0rem;
    font-size: 0.875em;
  }
  .buttons.style6 li a svg {
    width: 1em;
  }
  h1.style9,
  h2.style9,
  h3.style9,
  p.style9 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 2.5em;
    line-height: 1.125;
  }
  h1.style2,
  h2.style2,
  h3.style2,
  p.style2 {
    letter-spacing: -0.04375rem;
    width: 100%;
    font-size: 0.875em;
    line-height: 0.75;
  }
  h1.style4,
  h2.style4,
  h3.style4,
  p.style4 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 0.75em;
    line-height: 1.5;
  }
  h1.style11,
  h2.style11,
  h3.style11,
  p.style11 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 1.5em;
    line-height: 1.125;
  }
  h1.style12,
  h2.style12,
  h3.style12,
  p.style12 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 1.75em;
    line-height: 1.125;
  }
  h1.style10:not(:first-child),
  h2.style10:not(:first-child),
  h3.style10:not(:first-child),
  p.style10:not(:first-child) {
    margin-top: 0.875rem !important;
  }
  h1.style10:not(:last-child),
  h2.style10:not(:last-child),
  h3.style10:not(:last-child),
  p.style10:not(:last-child) {
    margin-bottom: 0.875rem !important;
  }
  h1.style10,
  h2.style10,
  h3.style10,
  p.style10 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 1.625em;
    line-height: 1.125;
  }
  h1.style3,
  h2.style3,
  h3.style3,
  p.style3 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 2.5em;
    line-height: 1.125;
  }
  h1.style13,
  h2.style13,
  h3.style13,
  p.style13 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 1.375em;
    line-height: 1.125;
  }
  h1.style8,
  h2.style8,
  h3.style8,
  p.style8 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 1.75em;
    line-height: 1.125;
  }
  h1.style7,
  h2.style7,
  h3.style7,
  p.style7 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 2.5em;
    line-height: 1.125;
  }
  h1.style6,
  h2.style6,
  h3.style6,
  p.style6 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 1.5em;
    line-height: 1.125;
  }
  #video01 video {
    width: 17.25rem;
}
#video01 .frame {
  width: 31.25rem;
}
  .video.style1 video {
    width: 17.5rem;
  }
  .video.style1 .frame {
    width: 17.5rem;
  }
  .video.style2 video {
    width: 14.375rem;
  }
  .video.style2 .frame {
    width: 14.375rem;
  }
  .container.style1 {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 30rem;
  }
  .container.style1:not(:first-child) {
    margin-top: 2.625rem !important;
  }
  .container.style1:not(:last-child) {
    margin-bottom: 2.625rem !important;
  }
  .container.style1 > .wrapper > .inner {
    --gutters: 4rem;
    --padding-horizontal: 0rem;
    --padding-vertical: 2.25rem;
  }
  .container.style1.columns > .wrapper > .inner {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }
  .container.style1.columns > .wrapper > .inner > span {
    height: 0;
    margin-top: calc(var(--gutters) * -1);
    pointer-events: none;
    visibility: hidden;
  }
  .container.style1.columns > .wrapper > .inner > *:first-child {
    margin-left: 0 !important;
    padding-top: 0 !important;
  }
  .container.style1.columns > .wrapper > .inner > * {
    padding: calc(var(--gutters) * 0.5) 0 !important;
  }
  .container.style1.columns > .wrapper > .inner > *:last-child {
    padding-bottom: 0 !important;
  }
  .container.style1.columns > .wrapper > .inner > div > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style1.columns > .wrapper > .inner > div:first-of-type > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style1.columns > .wrapper > .inner > div:last-of-type > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style1.columns > .wrapper > .inner > div > .full:first-child {
    margin-top: calc(var(--gutters) * -0.5) !important;
  }
  .container.style1.columns > .wrapper > .inner > div > .full:last-child {
    margin-bottom: calc(var(--gutters) * -0.5) !important;
  }
  .container.style1.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:first-child {
    margin-top: calc(var(--padding-vertical) * -1) !important;
  }
  .container.style1.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:last-child {
    margin-bottom: calc(var(--padding-vertical) * -1) !important;
  }
  .container.style1.columns > .wrapper > .inner > div:first-of-type,
  .container.style1.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .container.style1.columns > .wrapper > .inner > div:last-of-type,
  .container.style1.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  .container.style1.columns > .wrapper > .inner > div:first-of-type,
  .container.style1.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:last-child {
    border-bottom-left-radius: 0 !important;
  }
  .container.style1.columns > .wrapper > .inner > div:last-of-type,
  .container.style1.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:first-child {
    border-top-right-radius: 0 !important;
  }
  .container.style1.columns > .wrapper > .inner > .full {
    min-height: 30rem;
  }
  .container.style1.columns
    > .wrapper
    > .inner
    > .full
    > .full:first-child:last-child {
    height: auto;
  }
  .container.style1.columns
    > .wrapper
    > .inner
    > .full
    > .full:first-child:last-child
    > * {
    height: auto;
    position: relative;
    width: auto;
  }
  .container.style2 {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
  }
  .container.style2:not(:first-child) {
    margin-top: 2.625rem !important;
  }
  .container.style2:not(:last-child) {
    margin-bottom: 2.625rem !important;
  }
  .container.style2 > .wrapper > .inner {
    --gutters: 4rem;
    --padding-horizontal: 2rem;
    --padding-vertical: 0rem;
  }
  .container.style2.columns > .wrapper > .inner {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }
  .container.style2.columns > .wrapper > .inner > span {
    height: 0;
    margin-top: calc(var(--gutters) * -1);
    pointer-events: none;
    visibility: hidden;
  }
  .container.style2.columns > .wrapper > .inner > *:first-child {
    margin-left: 0 !important;
    padding-top: 0 !important;
  }
  .container.style2.columns > .wrapper > .inner > * {
    padding: calc(var(--gutters) * 0.5) 0 !important;
  }
  .container.style2.columns > .wrapper > .inner > *:last-child {
    padding-bottom: 0 !important;
  }
  .container.style2.columns > .wrapper > .inner > div > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style2.columns > .wrapper > .inner > div:first-of-type > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style2.columns > .wrapper > .inner > div:last-of-type > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style2.columns > .wrapper > .inner > div > .full:first-child {
    margin-top: calc(var(--gutters) * -0.5) !important;
  }
  .container.style2.columns > .wrapper > .inner > div > .full:last-child {
    margin-bottom: calc(var(--gutters) * -0.5) !important;
  }
  .container.style2.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:first-child {
    margin-top: calc(var(--padding-vertical) * -1) !important;
  }
  .container.style2.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:last-child {
    margin-bottom: calc(var(--padding-vertical) * -1) !important;
  }
  .container.style2.columns > .wrapper > .inner > div:first-of-type,
  .container.style2.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .container.style2.columns > .wrapper > .inner > div:last-of-type,
  .container.style2.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  .container.style2.columns > .wrapper > .inner > div:first-of-type,
  .container.style2.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:last-child {
    border-bottom-left-radius: 0 !important;
  }
  .container.style2.columns > .wrapper > .inner > div:last-of-type,
  .container.style2.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:first-child {
    border-top-right-radius: 0 !important;
  }
  .container.style2.columns
    > .wrapper
    > .inner
    > .full
    > .full:first-child:last-child {
    height: auto;
  }
  .container.style2.columns
    > .wrapper
    > .inner
    > .full
    > .full:first-child:last-child
    > * {
    height: auto;
    position: relative;
    width: auto;
  }
  #container03 > .wrapper > .inner > :nth-child(1) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container03 > .wrapper > .inner > :nth-child(2) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container02 > .wrapper > .inner > :nth-child(1) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container02 > .wrapper > .inner > :nth-child(2) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container05 > .wrapper > .inner > :nth-child(1) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container05 > .wrapper > .inner > :nth-child(2) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  .container.style7 {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
  }
  .container.style7:not(:first-child) {
    margin-top: 2.625rem !important;
  }
  .container.style7:not(:last-child) {
    margin-bottom: 2.625rem !important;
  }
  .container.style7 > .wrapper > .inner {
    --gutters: 4rem;
    --padding-horizontal: 0rem;
    --padding-vertical: 2.25rem;
  }
  .container.style7.columns > .wrapper > .inner {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }
  .container.style7.columns > .wrapper > .inner > span {
    height: 0;
    margin-top: calc(var(--gutters) * -1);
    pointer-events: none;
    visibility: hidden;
  }
  .container.style7.columns > .wrapper > .inner > *:first-child {
    margin-left: 0 !important;
    padding-top: 0 !important;
  }
  .container.style7.columns > .wrapper > .inner > * {
    padding: calc(var(--gutters) * 0.5) 0 !important;
  }
  .container.style7.columns > .wrapper > .inner > *:last-child {
    padding-bottom: 0 !important;
  }
  .container.style7.columns > .wrapper > .inner > div > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style7.columns > .wrapper > .inner > div:first-of-type > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style7.columns > .wrapper > .inner > div:last-of-type > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style7.columns > .wrapper > .inner > div > .full:first-child {
    margin-top: calc(var(--gutters) * -0.5) !important;
  }
  .container.style7.columns > .wrapper > .inner > div > .full:last-child {
    margin-bottom: calc(var(--gutters) * -0.5) !important;
  }
  .container.style7.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:first-child {
    margin-top: calc(var(--padding-vertical) * -1) !important;
  }
  .container.style7.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:last-child {
    margin-bottom: calc(var(--padding-vertical) * -1) !important;
  }
  .container.style7.columns > .wrapper > .inner > div:first-of-type,
  .container.style7.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .container.style7.columns > .wrapper > .inner > div:last-of-type,
  .container.style7.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  .container.style7.columns > .wrapper > .inner > div:first-of-type,
  .container.style7.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:last-child {
    border-bottom-left-radius: 0 !important;
  }
  .container.style7.columns > .wrapper > .inner > div:last-of-type,
  .container.style7.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:first-child {
    border-top-right-radius: 0 !important;
  }
  .container.style7.columns
    > .wrapper
    > .inner
    > .full
    > .full:first-child:last-child {
    height: auto;
  }
  .container.style7.columns
    > .wrapper
    > .inner
    > .full
    > .full:first-child:last-child
    > * {
    height: auto;
    position: relative;
    width: auto;
  }
  #container11 > .wrapper > .inner > :nth-child(1) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container11 > .wrapper > .inner > :nth-child(2) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container11 > .wrapper > .inner > :nth-child(3) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  .container.style6 {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 11.25rem;
  }
  .container.style6:not(:first-child) {
    margin-top: 2.625rem !important;
  }
  .container.style6:not(:last-child) {
    margin-bottom: 2.625rem !important;
  }
  .container.style6 > .wrapper > .inner {
    --gutters: 2rem;
    --padding-horizontal: 2rem;
    --padding-vertical: 0rem;
  }
  .container.style6.columns > .wrapper > .inner {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }
  .container.style6.columns > .wrapper > .inner > span {
    height: 0;
    margin-top: calc(var(--gutters) * -1);
    pointer-events: none;
    visibility: hidden;
  }
  .container.style6.columns > .wrapper > .inner > *:first-child {
    margin-left: 0 !important;
    padding-top: 0 !important;
  }
  .container.style6.columns > .wrapper > .inner > * {
    padding: calc(var(--gutters) * 0.5) 0 !important;
  }
  .container.style6.columns > .wrapper > .inner > *:last-child {
    padding-bottom: 0 !important;
  }
  .container.style6.columns > .wrapper > .inner > div > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style6.columns > .wrapper > .inner > div:first-of-type > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style6.columns > .wrapper > .inner > div:last-of-type > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
  }
  .container.style6.columns > .wrapper > .inner > div > .full:first-child {
    margin-top: calc(var(--gutters) * -0.5) !important;
  }
  .container.style6.columns > .wrapper > .inner > div > .full:last-child {
    margin-bottom: calc(var(--gutters) * -0.5) !important;
  }
  .container.style6.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:first-child {
    margin-top: calc(var(--padding-vertical) * -1) !important;
  }
  .container.style6.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:last-child {
    margin-bottom: calc(var(--padding-vertical) * -1) !important;
  }
  .container.style6.columns > .wrapper > .inner > div:first-of-type,
  .container.style6.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .container.style6.columns > .wrapper > .inner > div:last-of-type,
  .container.style6.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  .container.style6.columns > .wrapper > .inner > div:first-of-type,
  .container.style6.columns
    > .wrapper
    > .inner
    > div:first-of-type
    > .full:last-child {
    border-bottom-left-radius: 0 !important;
  }
  .container.style6.columns > .wrapper > .inner > div:last-of-type,
  .container.style6.columns
    > .wrapper
    > .inner
    > div:last-of-type
    > .full:first-child {
    border-top-right-radius: 0 !important;
  }
  .container.style6.columns > .wrapper > .inner > .full {
    min-height: 11.25rem;
  }
  .container.style6.columns
    > .wrapper
    > .inner
    > .full
    > .full:first-child:last-child {
    height: auto;
  }
  .container.style6.columns
    > .wrapper
    > .inner
    > .full
    > .full:first-child:last-child
    > * {
    height: auto;
    position: relative;
    width: auto;
  }
  #container09 > .wrapper > .inner > :nth-child(1) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container09 > .wrapper > .inner > :nth-child(2) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container08 > .wrapper > .inner > :nth-child(1) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container08 > .wrapper > .inner > :nth-child(2) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container06 > .wrapper > .inner > :nth-child(1) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  #container06 > .wrapper > .inner > :nth-child(2) {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    min-height: 100% !important;
    width: 100% !important;
  }
  .image.style11:not(:first-child) {
    margin-top: 2.125rem !important;
  }
  .image.style11:not(:last-child) {
    margin-bottom: 2.125rem !important;
  }
  .image.style11 .frame {
    width: 9.5rem;
    height: 11.875rem !important;
  }
  .image.style11 img {
    height: 100% !important;
    object-fit: cover;
    object-position: center;
    width: 100% !important;
  }
  .image.style13:not(:first-child) {
    margin-top: 2rem !important;
  }
  .image.style13:not(:last-child) {
    margin-bottom: 2rem !important;
  }
  .image.style13 .frame {
    width: 9.5rem;
    height: 11.875rem !important;
  }
  .image.style13 img {
    height: 100% !important;
    object-fit: cover;
    object-position: center;
    width: 100% !important;
  }
  .image.style12:not(:first-child) {
    margin-top: 1.5rem !important;
  }
  .image.style12:not(:last-child) {
    margin-bottom: 1.5rem !important;
  }
  .image.style12 .frame {
    width: 9.5rem;
    height: 11.875rem !important;
  }
  .image.style12 img {
    height: 100% !important;
    object-fit: cover;
    object-position: center;
    width: 100% !important;
  }
  .icons.style1 {
    font-size: 1.5em;
    gap: 1.25rem;
  }
}
@media (max-width: 480px) {
  #main > .inner {
    --spacing: 1.75rem;
  }
  .buttons.style3 li a {
    width: 15.625rem;
  }
  .buttons.style6 li a {
    width: 14.75rem;
  }
  .image.style11 .frame {
    height: 9.5rem !important;
  }
  .image.style13 .frame {
    height: 9.5rem !important;
  }
  .image.style12 .frame {
    height: 9.5rem !important;
  }
}
@media (max-width: 360px) {
  #main > .inner {
    --padding-horizontal: 1.5rem;
    --padding-vertical: 3rem;
    --spacing: 1.5rem;
  }
  .buttons.style2 {
    gap: 0.5625rem;
  }
  .buttons.style3 {
    gap: 0.5625rem;
  }
  .buttons.style4 {
    gap: 0.5625rem;
  }
  .buttons.style6 {
    gap: 0.5625rem;
  }
  h1.style9,
  h2.style9,
  h3.style9,
  p.style9 {
    font-size: 2.5em;
  }
  h1.style2,
  h2.style2,
  h3.style2,
  p.style2 {
    font-size: 0.875em;
  }
  h1.style4,
  h2.style4,
  h3.style4,
  p.style4 {
    font-size: 0.75em;
  }
  h1.style11,
  h2.style11,
  h3.style11,
  p.style11 {
    font-size: 1.5em;
  }
  h1.style12,
  h2.style12,
  h3.style12,
  p.style12 {
    font-size: 1.75em;
  }
  h1.style10,
  h2.style10,
  h3.style10,
  p.style10 {
    font-size: 1.625em;
  }
  h1.style3,
  h2.style3,
  h3.style3,
  p.style3 {
    font-size: 2.5em;
  }
  h1.style13,
  h2.style13,
  h3.style13,
  p.style13 {
    font-size: 1.375em;
  }
  h1.style13,
  h2.style13,
  h3.style13,
  p.style13 {
    color: #6e6e6e;
    font-family: "Inter", sans-serif;
    font-size: 1.25em;
    line-height: 1.125;
    font-weight: 600;
  }




  h1.style8,
  h2.style8,
  h3.style8,
  p.style8 {
    font-size: 1.75em;
  }
  h1.style7,
  h2.style7,
  h3.style7,
  p.style7 {
    font-size: 2.5em;
  }
  h1.style6,
  h2.style6,
  h3.style6,
  p.style6 {
    font-size: 1.5em;
  }
  .container.style1 > .wrapper > .inner {
    --gutters: 4rem;
    --padding-horizontal: 0rem;
    --padding-vertical: 1.6875rem;
  }
  .container.style1 {
    min-height: 20rem;
  }
  .container.style1.columns > .wrapper > .inner > .full {
    min-height: 20rem;
  }
  .container.style2 > .wrapper > .inner {
    --gutters: 4rem;
    --padding-horizontal: 1.5rem;
    --padding-vertical: 0rem;
  }
  .container.style7 > .wrapper > .inner {
    --gutters: 4rem;
    --padding-horizontal: 0rem;
    --padding-vertical: 1.6875rem;
  }
  .container.style6 > .wrapper > .inner {
    --gutters: 2rem;
    --padding-horizontal: 1.5rem;
    --padding-vertical: 0rem;
  }
  .container.style6 {
    min-height: 7.5rem;
  }
  .container.style6.columns > .wrapper > .inner > .full {
    min-height: 7.5rem;
  }
  .image.style11 .frame {
    height: 8.3125rem !important;
  }
  .image.style13 .frame {
    height: 8.3125rem !important;
  }
  .image.style12 .frame {
    height: 8.3125rem !important;
  }
  .icons.style1 {
    gap: 0.9375rem;
  }
}

body {
  overflow: auto !important;
}
body:after {
  display: none !important;
}
#main > .inner {
  opacity: 1 !important;
}
#main {
  opacity: 1 !important;
  transform: none !important;
  transition: none !important;
  filter: none !important;
}
.rainbow-btn {
  margin: 50px auto;
  width: 600px;
  text-align: center;
  position: relative;
  color: #ffffff;
  text-decoration: none;
  border-radius: 6px;
  box-sizing: border-box;
  display: block;
  z-index: 2;
  overflow: hidden;
  padding: 8px;
  height: 100px;
}
.rainbow-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    115deg,
    #4fcf70,
    #fad648,
    #a767e5,
    #12bcfe,
    #44ce7b
  );
  background-size: 50% 100%;
}
.rainbow-btn span {
  position: relative;
  z-index: 2;
  padding: 0.875rem 0;
  font-size: 1.1rem;
  text-decoration: none;
  align-items: center;
  background: #000;
  border-radius: 3px;
  display: block;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  font-size: 50px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.rainbow-btn:focus:before,
.rainbow-btn:hover:before {
  animation: rainbow-btn 0.75s linear infinite;
}

@keyframes rainbow-btn {
  to {
    transform: translateX(-50%);
  }
}
.youtube-container-rahul{
  position: relative;
 
  width: 15.125rem;
  padding-bottom: 177.77%; /* Aspect ratio for 9:16 */
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5); /* Hard shadow border */
  border-radius: 5px; /* Rounded corners */
}

.youtube-container-rahul iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px; /* Rounded corners for iframe */
}
.software-im-using-for-container{
  display: flex;
  margin: auto;
  width: 100%;
}
.each-software-image-ontainer{
  width: 20%;
}
.each-software-image-ontainer img{
  width: 80%;
  
}


/* Developer details */
.developer-details-r-m {
  margin: 2% auto;
  width: 80%;
  text-align: center;
  line-height: 27px;
}
.developer-details-r-m p{
 color: #aa8e03;
}
.developer-details-r-m p a{
 color: rgb(13, 141, 245);
 transition: all .3s ease;
 font-weight: bold;
 text-decoration: none;
 letter-spacing: 1px;
}
.developer-details-r-m p a:hover{
 color: rgb(32, 173, 255);
 transition: all .3s ease;
}